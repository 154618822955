// PTP每个key的含义
export const bluePtpFormTextObj = {
  ptpFileFormat: '输出文件格式',
  xPointSymbol: '横向坐标',
  yPointSymbol: '纵向坐标',
  zPointSymbol: '升降坐标',
  xDatumSwap: '板件镜像设置-X轴镜像',
  yDatumSwap: '板件镜像设置-Y轴镜像',
  beginCode: '程序开始代码',
  endCode: '程序结束代码',
  zzdKnifeBeginCode: '主轴刀换刀开始代码',
  zzdKnifeEndCode: '主轴刀换刀结束代码',
  czzBeginCode: '垂直钻启动代码',
  czzEndCode: '垂直钻结束代码',
  czzKnifeBeginCode: '垂直钻换刀开始代码',
  czzKnifeEndCode: '垂直钻换刀结束代码',
  czBeginCode: '侧钻启动代码',
  czEndCode: '侧钻结束代码',
  czKnifeBeginCode: '侧钻换刀开始代码',
  czKnifeEndCode: '侧钻换刀结束代码',
  pauseCode: '暂停代码',
  zSafeHeight: 'Z轴安全高度',
  xhDedicatedInfo: '辅助功能-生成星辉设备专用信息',
  xhE13Value: '辅助功能-数据 #E13=0',
  specialShapeKnives: '加工异形刀具编号',
  slotsKnives: '开槽刀具编号',
  size_annotate: '文件中生成小板尺寸注释',
  xyReverse: 'xy轴互换',
  fileSuffix: '文件后缀',
  zzdCutLast: '主轴刀在最后加工',
  fileFormatSetting: '文件格式设置',
}
// PTP每个value的含义
export const bluePtpFormValueObj = {
  // ptpFileFormat: {
  //   default: '输出默认格式文件',
  //   mpr: '输出MPR格式文件',
  // },
  xPointSymbol: {
    normal: '正值',
    negative: '负值',
  },
  yPointSymbol: {
    normal: '正值',
    negative: '负值',
  },
  zPointSymbol: {
    normal: '正值',
    negative: '负值',
  },
  fileFormatSetting: {
    platform: '换行符格式设置',
    fileEncoding: '文件编码格式设置',
    fileSuffix: '输出文件后缀',
  },
}
