import { render, staticRenderFns } from "./bluenPtp.vue?vue&type=template&id=350f1ea5&scoped=true&"
import script from "./bluenPtp.vue?vue&type=script&lang=js&"
export * from "./bluenPtp.vue?vue&type=script&lang=js&"
import style0 from "./bluenPtp.vue?vue&type=style&index=0&id=350f1ea5&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "350f1ea5",
  null
  
)

export default component.exports